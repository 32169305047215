<template>
  <TheContent
    title="Creator story Element"
    :countTotalItems="countTotalItems"
    :countItems="countItems"
    :countSelectedItems="countSelectedItems"
    :activePage.sync="page"
    :pages="countPages"
    :pageSize.sync="pageSize"
  >
    <!-- <pre>{{data}}</pre> -->

    <TableHeader slot="header">
      <CButton slot="btn" color="primary" @click="activeItem = null">Add tag</CButton>
    </TableHeader>

    <CDataTable
      hover
      striped
      sorter
      table-filter
      :loading="loading"
      :items="data"
      :fields="fields"
      index-column
    >
      <template #image="data">
        <td>
          <img style="width: 50px; heigth: 50px" :src="data.item.image">
        </td>
      </template>

      <template #created_at="data">
        <td>
          <span>{{ getCreatedAt(data.item.created_at) }}</span>
        </td>
      </template>

      <template #actions="data">
        <td>
          <Actions @edit="activeItem = data.item" @delete="() => handlerDelete(data.item)"/>
        </td>
      </template>
    </CDataTable>

    <template slot="modals">
      <ModalModificated
        :data="activeItem"
        :isShow="activeItem !== false"
        :addictionsData="addictionsData"
        :apiName="apiName"
        :apiKey="apiKey"
        @close="activeItem = false"
        @submit="handlerSubmitModal"
      />
    </template>
  </TheContent>
</template>

<script>
import moment from "moment";

import TheContent from "@/containers/TheContent";

import MixinsScreen from "@/mixins/screen";

import Actions from "@/components/root/Actions";

import { axios, showNotification } from "@/utils/axios";

import ModalModificated from "./modals/element/ModalModificated";

export default {
  name: "Creator story",

  mixins: [MixinsScreen],

  components: {
    TheContent,

    Actions,

    ModalModificated
  },

  data: () => {
    return {
      apiName: "creator-story",
      apiKey: "element",

      fields: [
        { key: "id", label: "Id" },
        { key: "image", label: "Image" },
        { key: "type", label: "Type" },
        { key: "created_at", label: "Created At" },
        { key: "actions", label: "Actions" }
      ]
    };
  },

  methods: {
    getBadge(status) {
      return status ? "success" : "danger";
    },

    getCreatedAt(date) {
      return moment(date)
        .subtract("hours", 3)
        .format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
