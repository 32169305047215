<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.image && form.image.file"
            name="image"
            :image="form.image && form.image.url ? form.image.url : form.image"
            @input="value => handleChangeFile({ value, key: 'image' })"
          />
        </CCol>

        <CCol sm="6">
          <MultiselectServerSearch
            v-model="form.type"
            label="Type"
            placeholder="Type"
            :data="typeOptions"
            :multiple="false"
          />

          <!-- <AppSlect
            v-model="form.tags"
            :options="tagOptions"
            multiple
            label="Tag"
          />-->
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import FileLoader from "@/components/root/FileLoader";
import MultiselectServerSearch from "@/components/root/MultiselectServerSearch";

import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    FileLoader,
    MultiselectServerSearch
  },

  data: () => ({
    form: {
      image: null,
      type: null
    },

    typeOptions: [
      { value: "watercolor", label: "Watercolor" },
      { value: "glitter", label: "Glitter" },
      { value: "tape", label: "Tape" },
      { value: "stroke", label: "Stroke" },
      { value: "abstract", label: "Abstract" },
      { value: "line-art", label: "Line Art" }
    ]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data ? "Edit Instapano element" : "Add Instapano element";
      }
    },

    tagOptions() {
      let res = [];

      for (let item of this.addictionsData.tag) {
        res.push({ value: item.id, label: item.name });
      }

      return res;
    }
  },

  created() {
    for (let lang of this.languages) {
      this.form[`name_${lang}`] = null;
    }
  },

  methods: {
    handleChangeFile({ value, key }) {
      this.form[key] = {
        type: "upload",
        file: value,
        url: URL.createObjectURL(value)
      };

      this.$emit("change", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
